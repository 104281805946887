import first from 'lodash/first'
import { FC, useMemo } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { Flag } from 'atoms'

interface ListItemProps {
  vayapin?: string;
  onClick: () => void;
}

const ListItem: FC<ListItemProps> = ({
  vayapin,
  onClick,
}) => {
  const country = useMemo(
    () => first(vayapin?.split(':')) || '',
    [vayapin]
  )

  return (
    <li className="item">
      <button
        type="button"
        className="button-reset"
        onClick={onClick}
      >
        <span className="flag">
          <Flag country={country} width={24} />&nbsp;
        </span>
        <span className="vayapin">
          {vayapin}
        </span>
        <span className="icon">
          <FaChevronRight />
        </span>
      </button>
    </li>
  )
}

export default ListItem
