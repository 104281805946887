import { gql } from '@apollo/client'

const query = gql`
  query pin($id: String!) {
    pin(id: $id) {
      vayapin
      country
      name
      currentActiveTo
      currentActiveFrom
    }
  }
`

export default query
