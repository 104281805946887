import type {
  History,
  HistoryEntry,
} from './Types'

import filter from 'lodash/filter'
import isArray from 'lodash/isArray'
import isDate from 'lodash/isDate'
import isObject from 'lodash/isObject'
import sortBy from 'lodash/sortBy'
import { isPresent } from 'lib/utils'

let history: History | null = null

const LS_KEY = 'history'

/**
 * Load the history from localStorage
 */
export function loadHistory(): History {
  const storedData = localStorage.getItem(LS_KEY) || '[]'

  try {
    let h = (JSON.parse(storedData) || []) as History
    h = sortBy(
      h,
      (e) => (new Date()).getTime() - new Date(e.datetime).getTime()
    ).map((e) => {
      e.vayapin = e.vayapin?.toUpperCase()
      if (typeof e.datetime === 'string') e.datetime = new Date(e.datetime)
      return e
    })

    history = isArray(h) ? h : []
  } catch (e) {
    history = []
  }

  return history
}

/**
 * Returns the history object. If not existant, it'll
 */
export function getHistory(): History {
  if (!history) loadHistory()

  return history || []
}

/**
 * Search history for specific entries
 */
export function searchHistory(
  search: string,
  country: string | undefined,
  limit = 5
): History {
  const h = getHistory()
  if (h.length <= 0) return []

  const regex = new RegExp(`^${search}`, 'i')
  const result = filter(
    h,
    (entry) => {
      const split = entry.vayapin.split(':')
      if (isPresent(country)
          && split[0].toLowerCase() !== country.toLowerCase()) {
        return false
      }
      return regex.test(split[1])
    }
  )
  return result.slice(0, limit)
}

/**
 * Checks if the passed entry is valid.
 */
export function isValidHistoryEntry(entry?: HistoryEntry): boolean {
  if (!isObject(entry)) return false
  if (!isPresent(entry.vayapin)) return false

  return isDate(entry.datetime)
}

/**
 * Pass in a history entry to add it to the history. If there
 * is already a entry for the VayaPin, it'll update the
 * datetime field.
 */
export function updateHistoryEntry(
  entry?: HistoryEntry,
): History {
  if (!entry || !isValidHistoryEntry(entry)) return []

  entry.vayapin = entry.vayapin.toUpperCase()

  const h = getHistory()

  if (!h) return persistHistory([entry])

  const index = h.findIndex(
    (item) => item.vayapin === entry.vayapin
  )

  if (index >= 0) {
    h[index] = entry
    return persistHistory([...h])
  }

  return persistHistory([...h, entry])
}

/**
 * Save history to localStorage
 */
export function persistHistory(newHistory: History): History {
  localStorage.setItem(LS_KEY, JSON.stringify(newHistory))
  loadHistory()
  return getHistory()
}
