'use client'

import { useSignedIn } from '@vayapin/oauth-kit-react'
import { Button } from 'components/atoms'
import { useTranslation } from 'i18n/client'
import CountrySelectButton from 'organisms/Form/CountrySelectButton'
import Form, { Field } from 'rc-field-form'
import { FC } from 'react'
import { AiOutlineRight } from 'react-icons/ai'
import { BsArrow90DegUp } from 'react-icons/bs'
import ButtonSubmit from './ButtonSubmit'
import FieldInput from './FieldInput'
import Styles from './PinSearchForm.module.sass'
import Suggestions from './Suggestions'
import type { PinSearchFormProps } from './Types'
import withData from './withData'

const PinSearchForm: FC<PinSearchFormProps> = ({
  form,
  submittable,
  showCountryHint,
  loading,
  error,
  suggestions,
  hasSuggestions,
  onFormFinish,
  onFormValuesChange,
  onClickSuggestion,
  inputRef,
}) => {
  const { t } = useTranslation()
  const signedIn = useSignedIn()

  return (
    <Form
      form={form}
      name="vayapin_pin"
      autoComplete="off"
      onFinish={onFormFinish}
      onValuesChange={onFormValuesChange}
      className={Styles.form}
    >
      <div className="inside">
        <div className="formWrapper">
          <Field name="country">
            <CountrySelectButton className={Styles.countyButton} />
          </Field>
          <FieldInput inputRef={inputRef} />
          <ButtonSubmit
            enabled={submittable}
            loading={loading}
          />
        </div>
        {hasSuggestions && (
          <Suggestions
            {...suggestions}
            onClick={onClickSuggestion}
          />
        )}
      </div>
      {error && (
        <div className={Styles.formError}>
          {error}
        </div>
      )}
      {showCountryHint && (
        <div className={Styles.countryHint}>
          <span>
            <BsArrow90DegUp />&nbsp;
          </span>
          <em>
            {t('pinSearchForm.countryHint', 'Please select a country first')}
          </em>
        </div>
      )}
      {
        !signedIn ? (
          <div className={Styles.btnContainer}>
            <div className={Styles.createPinBtnContainer}>
              <Button
                type='link'
                href='/account/create-pin'
                theme='outline'
                className={Styles.btnCreatePin}
              >
                {t('pinSearchForm.createAddress', 'Create an address')}&nbsp;
                <AiOutlineRight/>
              </Button>
            </div>
            <Button
              type='link'
              theme='link'
              href='/account'
              className={Styles.btnSignIn}
            >
              {t('pinSearchForm.signIn', 'or Sign in')}
            </Button>
          </div>
        ): null
      }
    </Form>
  )
}

export default withData(PinSearchForm)
