import type { History, HistoryEntry } from 'services/History'
import type { PinSearchEntry } from '../querySearch'
import { FC, useMemo } from 'react'
import { useTranslation } from 'i18n/client'
import ListItemGroup from './ListItemGroup'
import Styles from './Suggestions.module.sass'

interface SuggestionsProps {
  history?: History;
  search?: PinSearchEntry[];
  onClick: (entry: PinSearchEntry | HistoryEntry) => void;
}

export interface Group {
  readonly key: string;
  readonly label: string;
  readonly options: readonly HistoryEntry[] | readonly PinSearchEntry[];
}

const Suggestions: FC<SuggestionsProps> = ({
  history = [],
  search = [],
  onClick,
}) => {
  const { t } = useTranslation()

  const groups = useMemo<Group[]>(() => [
    {
      key: 'bookmarks',
      label: t('pinSearchForm.suggestions.groupHistory', 'History'),
      options: history,
    },
    {
      key: 'suggestions',
      label: t('pinSearchForm.suggestions.groupSuggestions', 'Suggestions'),
      options: search,
    },
  ], [history, search, t])

  return (
    <div className={Styles.container}>
      <ul>
        {groups.map((group) => (
          <ListItemGroup
            key={group.key}
            group={group}
            onClick={onClick}
          />
        ))}
      </ul>
    </div>
  )
}

export default Suggestions
