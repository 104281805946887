import { useForm } from 'atoms/Form'
import { useTranslation } from 'i18n/client'
import { useQueryError } from 'lib/hooks'
import { isPresent } from 'lib/utils'
import first from 'lodash/first'
import { useRouter } from 'next/navigation'
import {
  ComponentType, FC, useCallback, useEffect, useMemo, useState,
} from 'react'
import { useCookies } from 'react-cookie'
import { History, HistoryEntry, searchHistory } from 'services/History'

import { useLazyQuery } from '@apollo/client'
import { Query } from '@vayapin/cs-types-public'

import {
  formSetCountry, handleFormValuesChange, validateQueryData,
} from './Helpers'
import query from './query'
import querySearch, {
  PinSearchEntry, PinSearchQueryData, PinSearchVariables,
} from './querySearch'

import type {
  FormData,
  PinSearchFormExposedProps,
  PinSearchFormProps,
} from './Types'
import useInputFocus from './useInputFocus'

function withData(Component: ComponentType<PinSearchFormProps>) {
  const WithData: FC<PinSearchFormExposedProps> = ({
    ...restProps
  }) => {
    const { t } = useTranslation()
    const form = useForm<FormData>()
    const [submittable, setSubmittable] = useState(false)
    const [showCountryHint, setShowCountryHint] = useState(false)
    const [error, setError] = useState<string | undefined>(undefined)
    const [history, setHistory] = useState<History>([])
    const [searchResult, setSearchResult] = useState<PinSearchEntry[]>([])
    const [cookies, setCookie] = useCookies(['selectedCountry'])
    const router = useRouter()
    const [inputRef, setInputFocus] = useInputFocus()

    //
    // query
    const [fetch, {
      data: queryData,
      error: queryError,
      loading: queryLoading,
    }] = useLazyQuery<Query>(
      query,
      { fetchPolicy: 'network-only' }
    )

    //
    // query search
    const [searchFetch, {
      error: querySearchError,
      // loading: querySearchLoading,
    }] = useLazyQuery<PinSearchQueryData>(
      querySearch,
      { fetchPolicy: 'network-only' }
    )

    //
    // handle errors
    useQueryError(queryError)
    useQueryError(querySearchError)

    //
    //
    const onSuccess = useCallback((vayapin: string) => {
      void router.push(`/${vayapin}`)
      return undefined
    }, [router])

    //
    // Check if form is submittable
    const checkSubmittable = useCallback(() => {
      const country = form.getFieldValue('country')
      const name = form.getFieldValue('name')
      const submittable = isPresent(country)
        && isPresent(name)
        && country.length === 2
      setSubmittable(submittable)
    }, [form])

    //
    // Check if we need to show the country hint
    const checkShowCountryHint = useCallback(() => {
      const country = form.getFieldValue('country')
      const show = !error
        && (!isPresent(country) || country.length < 2)
      setShowCountryHint(show)
    }, [error, form])

    //
    // Lookup history
    const lookupHistoryEntries = useCallback(() => {
      const country = form.getFieldValue('country')
      const name = form.getFieldValue('name')

      if (isPresent(name)) {
        setHistory(searchHistory(name, country as string))
      } else {
        setHistory([])
      }
    }, [form])

    //
    // Run api search
    const lookupSearchEntries = useCallback(async () => {
      const country = form.getFieldValue('country')
      const name = form.getFieldValue('name')

      if (!isPresent(name) || name.length < 4) {
        setSearchResult([])
        return
      }

      const variables: PinSearchVariables = { search: name }
      if (isPresent(country)) variables.country = country

      const result = await searchFetch({ variables })
      setSearchResult(result?.data?.pinSearch?.nodes || [])
    }, [form, searchFetch])

    //
    // Update the country cookie
    const updateCountryCookie = useCallback(() => {
      const country = form.getFieldValue('country')
      if (isPresent(country)) setCookie('selectedCountry', country)
    }, [form, setCookie])

    //
    // Handle values change
    const onFormValuesChange = useCallback((values: FormData) => {
      handleFormValuesChange(form, values)
      updateCountryCookie()
      checkSubmittable()
      checkShowCountryHint()
      lookupHistoryEntries()
      void lookupSearchEntries()
      setInputFocus()
    }, [
      checkShowCountryHint,
      checkSubmittable,
      form,
      lookupHistoryEntries,
      lookupSearchEntries,
      updateCountryCookie,
      setInputFocus,
    ])

    //
    // Handle form finish
    const onFormFinish = useCallback((values: FormData) => {
      const vayapin = [
        values?.country,
        values?.name
      ].join(':').toUpperCase()

      void fetch({ variables: { id: vayapin } })
    }, [fetch])

    //
    // React on suggestions click
    const onClickSuggestion = useCallback((
      entry: PinSearchEntry | HistoryEntry
    ) => {
      onSuccess(entry.vayapin ?? '')
    }, [onSuccess])

    //
    // Build suggestions
    const suggestions = useMemo(
      () => ({ history, search: searchResult }),
      [history, searchResult]
    )

    const hasSuggestions = useMemo(
      () => suggestions?.history?.length > 0
        || suggestions?.search?.length > 0,
      [suggestions?.history?.length, suggestions?.search?.length]
    )

    //
    // handle fetch data
    useEffect(
      () => {
        validateQueryData(t, form, onSuccess, queryData)
        const error = first(form.getFieldError('name'))
        setError(isPresent(error) ? error : undefined)
      },
      [form, onSuccess, queryData, t]
    )

    //
    // Select country by cookie
    useEffect(() => {
      formSetCountry(form, cookies['selectedCountry'] as string)
    }, [cookies, form])

    //
    // Find preselected country
    useEffect(() => {
      checkShowCountryHint()
    }, [checkShowCountryHint])

    //
    // Render component
    //
    return (
      <Component
        {...restProps}
        form={form}
        submittable={submittable}
        showCountryHint={showCountryHint}
        loading={queryLoading}
        error={error}
        suggestions={suggestions}
        hasSuggestions={hasSuggestions}
        onFormFinish={onFormFinish}
        onFormValuesChange={onFormValuesChange}
        onClickSuggestion={onClickSuggestion}
        inputRef={inputRef}
      />
    )
  }

  // Set the display name as composition of name
  // and wrapped components name
  const wrappedDisplayName = Component.displayName || Component.name || 'Component'
  WithData.displayName = `WithData(${wrappedDisplayName})`

  // Return wrapper
  return WithData
}

export default withData
