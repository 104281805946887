import { FC, useMemo } from 'react'
import classnames from 'classnames'
import { LoadingIndicator } from 'atoms'
import Styles from './ButtonSubmit.module.sass'

interface ButtonSubmitProps {
  enabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

const ButtonSubmit: FC<ButtonSubmitProps> = ({
  enabled = false,
  loading = false,
}) => {
  const classes = useMemo(() => classnames({
    'button': true,
    [Styles.button]: true,
    [Styles.buttonEnabled]: enabled,
    [Styles.buttonLoading]: loading,
  }), [enabled, loading])

  return (
    <button
      type="submit"
      className={classes}
      disabled={!enabled}
    >
      {loading ? (
        <LoadingIndicator />
      ) : 'Go'}
    </button>
  )
}

export default ButtonSubmit
