import type { HistoryEntry } from 'services/History'
import type { PinSearchEntry } from '../querySearch'
import type { Group } from './Types'
import { FC } from 'react'
import ListItem from './ListItem'

interface ListItemGroupProps {
  group: Group;
  onClick: (entry: PinSearchEntry | HistoryEntry) => void;
}

const ListItemGroup: FC<ListItemGroupProps> = ({
  group,
  onClick,
}) => group.options?.length > 0 ? (
  <>
    <li className="group" key={group.key}>
      {group.label}
    </li>
    {group.options.map((entry) => (
      <ListItem
        key={`${group.key}-${entry.vayapin || ''}`}
        vayapin={entry.vayapin}
        onClick={() => onClick(entry)}
      />
    ))}
  </>
) : null

export default ListItemGroup
