import { Field } from 'atoms/Form'
import { useTranslation } from 'i18n/client'
import { FC, RefObject, memo } from 'react'

interface InputProps {
  inputRef: RefObject<HTMLInputElement> | (() => void);
}

const Input: FC<InputProps> = memo(({ inputRef }) => {
  const { t } = useTranslation()

  return (
    <Field name="name">
      <input
        className="input"
        autoComplete="off"
        placeholder={t('pinSearchForm.placeholder', 'VayaPin Name') || ''}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        ref={inputRef}
      />
    </Field>
  )
})
Input.displayName = 'Input'

export default Input
