import { gql } from '@apollo/client'

export interface PinSearchEntry {
  vayapin?: string;
  key?: string;
  country?: string;
  name?: string;
}

export interface PinSearchQueryData {
  pinSearch?: {
    nodes?: PinSearchEntry[]
  };
}

export interface PinSearchVariables {
  search: string;
  country?: string | undefined;
}

const query = gql`
  query pinSearch(
    $search: String!,
    $country: String,
  ) {
    pinSearch(
      search: $search,
      country: $country,
      bilateral: true
    ) {
      nodes {
        vayapin
        key: vayapin
        country
        name
      }
    }
  }
`

export default query
