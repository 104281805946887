import { RefObject, useCallback, useMemo, useRef } from 'react'

type UseInputFocusReturn = [
  RefObject<HTMLInputElement>,
  () => void
]

function useInputFocus(): UseInputFocusReturn  {
  const ref = useRef<HTMLInputElement>(null)

  const setFocus = useCallback(() => {
    ref.current &&  ref.current.focus()
  }, [ref])

  return useMemo(() => [
    ref, setFocus
  ], [ref, setFocus])
}

export default useInputFocus
