import type { Query } from '@vayapin/cs-types-public'
import type { FormData } from './Types'

import { isPresent } from 'lib/utils'
import has from 'lodash/has'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import { TFunction } from 'i18next'
import { FormInstance } from 'rc-field-form'

export function validateQueryData(
  t: TFunction,
  form: FormInstance,
  onSuccess?: (pin: string) => unknown,
  queryData?: Query,
): void {
  if (!isObject(queryData)) return

  const pin = queryData?.pin?.vayapin

  if (isPresent(pin)) {
    form.setFields([{ name: 'name', errors: [] }])
    if (isFunction(onSuccess)) onSuccess(pin)
  } else {
    form.setFields([{
      name: 'name', errors: [
        t('pinSearchForm.couldNotFind', 'Could not find the Vayapin.')
      ]
    }])
  }
}

const CHAR_REGEX = /[^a-z0-9]/gi

function charTransform(str?: string) {
  return (str || '').replace(CHAR_REGEX, '').toUpperCase()
}

export function handleFormValuesChange(
  form: FormInstance,
  values: FormData,
): void {
  if (has(values, 'name')) {
    const split = (values?.name || '').split(/[.:]/)

    if (split.length > 1 && split[0].length === 2) {
      form.setFields([{
        name: 'country',
        value: charTransform(split[0]),
      }, {
        name: 'name',
        value: charTransform(split[1]),
      }])
    } else {
      form.setFields([{
        name: 'name',
        value: charTransform(split[0]),
      }])
    }
  } else if (has(values, 'country')) {
    form.setFields([{
      name: 'country',
      value: charTransform(values?.country),
    }])
  }
}

export function formSetCountry(
  form: FormInstance,
  country?: string,
): void {
  if (!isPresent(country) || country.length !== 2) return

  form.setFields([{
    name: 'country',
    value: charTransform(country),
  }])
}
