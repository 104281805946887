'use client'

import type { FieldProps } from 'atoms/Form'
import { FC, useCallback, useState } from 'react'
import { FaCaretDown, FaGlobeAmericas } from 'react-icons/fa'
import { Flag } from 'atoms'
import CountrySelectionModal from 'organisms/client/CountrySelectionModal'
import Styles from './CountrySelectButton.module.sass'
import { Country } from '@vayapin/cs-types-public'

export interface CountrySelectorProps<
  Values = unknown
> extends FieldProps<Values> {
  className?: string,
  value?: string,
  onChange?: (country: string) => void;
}

const CountrySelector: FC<CountrySelectorProps> = ({
  className,
  value,
  onChange = (_country: string) => undefined,
}) => {
  const [modalVisible, setModalVisible] = useState(false)

  const onModalCancel = useCallback(() => {
    setModalVisible(false)
  }, [])

  const onModalClickItem = useCallback(({ iso31661 }: Country) => {
    onChange(iso31661 as string)
    setModalVisible(false)
  }, [onChange])

  return (
    <>
      <button
        type="button"
        onClick={() => setModalVisible(true)}
        className={`button-reset button ${Styles.button} ${className || ''}`}
      >
        <Flag
          country={value}
          fallback={<FaGlobeAmericas />}
          height={30}
          width={30}
        />
        <FaCaretDown className={`${Styles.icon} icon`} />
      </button>
      {modalVisible && (
        <CountrySelectionModal
          selectedItem={value}
          onCancel={onModalCancel}
          onClickItem={onModalClickItem}
        />
      )}
    </>
  )
}

export default CountrySelector
